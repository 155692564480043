import React, { useState, useEffect } from "react";
import { forwardRef } from "react";
import MaterialTable, { MTablePagination } from "material-table";
import { Button, Row, Col } from "react-bootstrap";
import Select from "react-select";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import * as XLSX from 'xlsx';
import makeStyles from "@mui/styles/makeStyles";
import Paper from "@mui/material/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import { TablePagination } from "@material-ui/core";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";

import ArrowDownward from "@mui/icons-material/ArrowDownward";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Clear from "@mui/icons-material/Clear";
import FilterList from "@mui/icons-material/FilterList";
import FirstPage from "@mui/icons-material/FirstPage";
import LastPage from "@mui/icons-material/LastPage";
import Search from "@mui/icons-material/Search";
import ViewColumn from "@mui/icons-material/ViewColumn";
import CheckRole from "../../CheckRole.js";
import AADService from "../../funcionesAuth.js";
import { DecryptData } from "../../DatosEncriptados/EncryptedFunctions.js";
import ModalFinding from "./ModalFinding.js";
import CircularProgress from "@mui/material/CircularProgress";

// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
// import TableRow from "@mui/material/TableRow";

const tableIcons = {
  //Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

function Findings() {
  const useStyles = makeStyles((theme) => ({
    container: {
      maxHeight: "55vh",
      minHeight: "55vh",
    },
    root: {
      width: "100%",
    },
    pagination: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    switch: {
      paddingLeft: "1.5em",
    },
    inside: {
      backgroundColor: "white",
      width: "100%",
      // margintom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    head: {
      backgroundColor: "#2c2a29",
      color: "white",
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
      //zIndex: 1,
    },
    palette: {
      primary: {
        main: "#4caf50",
      },
      secondary: {
        main: "#ff9100",
      },
    },
  }));

  const classes = useStyles();
  const [filter, setFilter] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const columns = [
    { field: "id", title: "ID Engagement" },
    { field: "name", title: "Name" },
    { field: "engagement_type", title: "Engagement Type" },
    { field: "finding_id", title: "Finding ID" },
    { field: "finding_title", title: "Title" },
    { field: "finding_cve", title: "CVE" },
    {
      field: "finding_severity",
      title: "Severity",
      render: rowData => {
        let color;
        switch (rowData.finding_severity) {
          case 'High':
            color = 'red';
            break;
          case 'Critical':
            color = 'darkred';
            break;
          case 'Medium':
            color = 'orange';
            break;
          case 'Low':
            color = 'green';
            break;
          default:
            color = 'black';
        }
        return <span style={{ color }}>{rowData.finding_severity}</span>;
      }
    },
    { field: "finding_description", title: "Description" },
    { field: "finding_active", title: "Active" },
    { field: "finding_verified", title: "Verified" },
    { field: "finding_risk_status", title: "Risk Status" },
    { field: "finding_risk_accepted", title: "Risk Accepted" },
    { field: "finding_accepted_by", title: "Accepted By" },
  ];

  const [findings, setFindings] = React.useState([]);
  const check = new CheckRole("/findings");
  const [opcionesGenerales, setOpcionesGenerales] = React.useState([]);
  const [open, setOpen] = useState(false);

  const idactivo = localStorage.getItem("idactivo");
  const AzureADService = new AADService();
  const token = AzureADService.getToken();
  function ExportarExcel(data_findings) {
    // Crear un nuevo libro de trabajo
    const newBook = XLSX.utils.book_new();

    data_findings.forEach(function(json){
      delete json.tableData;
    });
    // Convertir los datos JSON a una hoja de Excel
    const sheet1 = XLSX.utils.json_to_sheet(data_findings);

    // Agregar la hoja al libro de trabajo
    XLSX.utils.book_append_sheet(newBook, sheet1, "Findings");

    // Guardar el archivo con un nombre generado dinámicamente
    const fileName = "Findings" + idactivo + ".xlsx";
    XLSX.writeFile(newBook, fileName);
}

  useEffect(() => {
    const fetchFindings = async () => {
      setLoading(true);
      const result = await fetch(
        process.env.REACT_APP_API_URL + "maestro/findings/" + idactivo 
        + "/FILE/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      if (result.status >= 200 && result.status <= 300) {
        let data = await result.json();
        setFindings(data['data']);
        alert(data['message']);
        setLoading(false);
      } else if (result.status >= 400 && result.status <= 500) {
        alert("No se encontraron findings en esta evaluación");
        setFindings([]);
        setLoading(false);
      }
    };

    const fetchUsuario = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL +
          "usuarios/menu/" +
          localStorage.getItem("usuario") +
          "/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      const encryptedUser = await result.json();
      const decryptedUser = DecryptData(encryptedUser);
      let usuario = JSON.parse(decryptedUser);
      let opciones = [];
      let permisos = [];
      for (let i = 0; i < usuario.length; i++) {
        permisos = usuario[i].permisos.split(";");
        opciones.push({ opcion: usuario[i].url_opcion, permisos: permisos });
      }
      setOpcionesGenerales(opciones);
      var validity = check.checkPermits(opciones, "GET");
      if (validity) {
        fetchFindings();
      }
    };
    fetchUsuario();
    
  }, []);

  const handleChange = () => {
    setFilter(!filter);
  };

  const [selected, setSelected] = React.useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [ButtonEdit, SetButtonEdit] = React.useState(false);

  const handleClick = (id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat([], id);
      SetButtonEdit(true);
    } else {
      SetButtonEdit(false);
    }
    setSelected([newSelected]);
    //SetButtonEdit(true);
  };

  const ver = () => {
    // Guarda el ID de la fila seleccionada en localStorage o úsalo directamente
    localStorage.setItem("finding_id", selected);
    setOpen(true); // Abre el modal
  };

  return (
    <div>
      {/* <ModalFinding open={open} setOpen={setOpen} selected={selected} /> */}
      <Row>
        {/* {ultimaModificacion != null ? (<Col>
          <h1>Findings (Vulnerabilidades técnicas)</h1>
          <br></br>
          <h2>Última modificación: {ultimaModificacion}</h2>
        </Col>):(
        <Col>
          <h1>Findings (Vulnerabilidades técnicas)</h1>
        </Col>
      )} */}
        <Col>
          <h1>Findings (Vulnerabilidades técnicas)</h1>
        </Col>
      </Row>

      <Row>
        <Col>
          <div style={{ zIndex: 100 }}>
            <Paper variant="outlined" className={classes.paper}>
              <MaterialTable
                columns={columns}
                data={findings}
                icons={tableIcons}
                options={{
                  rowStyle: (rowData) => ({
                    backgroundColor:
                      ButtonEdit === true && selectedRow === rowData.finding_id
                        ? "#ffdb94"
                        : "#FFF",
                  }),
                  showTitle: false,
                  filtering: filter,
                  searchFieldAlignment: "left",
                  searchFieldStyle: { height: 50 },
                  //zIndex: -1000,
                  headerStyle: {
                    //zIndex: 1,
                    backgroundColor: "#2c2a29",
                    color: "#ffffff",
                    position: "relative",
                    top: 0,
                    fontFamily: "CIBFont Sans Regular",
                    fontWeight: 400,
                    fontSize: "1.2rem",
                    lineHeight: 1.6,
                    letterSpacing: "0.0075em",
                    flex: "1 1 100%",
                  },
                  maxBodyHeight: "650px",
                  selection: false,
                  disableMultipleSelection: true,
                  pageSizeOptions: [10, 20, 30],
                  showSelectAllCheckbox: false,
                  showTextRowsSelected: false,
                  pageSize: 10,
                }}
                components={{
                  Pagination: (props) => (
                    <div className={classes.pagination}>
                      <FormControlLabel
                        className={classes.switch}
                        control={
                          <Checkbox
                            checked={filter}
                            onChange={handleChange}
                            inputProps={{ "aria-label": "primary checkbox" }}
                          />
                        }
                        label={
                          <Typography variant="body2">
                            Mostrar filtros por columnas
                          </Typography>
                        }
                      />
                      <TablePagination
                        {...props}
                        className={classes.pagination}
                      />
                    </div>
                  ),
                }}
                onRowClick={(evt, rowData) => {
                  handleClick(rowData.finding_id);
                  setSelectedRow(rowData.finding_id);
                }}
                actions={[
                  loading
                    ? {
                        icon: () => <CircularProgress />,
                        position: "toolbar",
                        isFreeAction: true,
                      }
                    :
                    {
                      icon: () => (
                      <Button
                      className="botonDescargar"
                    >
                      Descargar Findings
                    </Button>
                    ),
                    onClick: () => ExportarExcel(findings),
                    position: "toolbar",
                    isFreeAction: true,
                    },
                    {
                        icon: () => (
                          <Button className="botonGeneral">Ver detalles</Button>
                        ),
                        onClick: () => ver(), // <-- Aquí llamamos a la función al hacer clic (sin ejecutarla de inmediato)
                        position: "toolbar",
                        isFreeAction: true,
                        hidden: !ButtonEdit, // <-- Oculta el botón si no hay fila seleccionada
                      },
                ]}
                localization={{
                  toolbar: {
                    searchPlaceholder: "Buscar",
                  },
                  body: {
                    emptyDataSourceMessage: "No se encontraron registros",
                  },
                  pagination: {
                    labelRowsSelect: "Filas",
                    firstTooltip: "Primera página",
                    previousTooltip: "Anterior",
                    nextTooltip: "Siguiente",
                    lastTooltip: "Última página",
                  },
                }}
              />
            </Paper>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Findings;






