import React, { useState } from "react";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import { Button, Row, Col, Form, Alert, Pagination } from "react-bootstrap";
import AADService from "../funcionesAuth.js";

function S3View() {
  const [files, setFiles] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [alert, setAlert] = useState({ show: false, message: "", variant: "" });
  const [folder, setFolder] = useState("");
  const [search, setSearch] = useState("");
  const [selectedFilesToUpload, setSelectedFilesToUpload] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});
  // Nuevos estados para paginación
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 50;

  const AzureADService = new AADService();
  const token = AzureADService.getToken();

  const fetchFiles = async (folderName = "") => {
    try {
      const url = folderName
        ? `${process.env.REACT_APP_API_URL}administrador/s3/files/${folderName}/`
        : `${process.env.REACT_APP_API_URL}administrador/s3/files/`;
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) throw new Error("Error fetching files");
      const data = await response.json();
      const fileList = data.files || [];
      setFiles(fileList);
      // Reiniciamos la página al hacer una nueva búsqueda
      setCurrentPage(1);
    } catch (error) {
      setAlert({
        show: true,
        message: "Error fetching files",
        variant: "danger",
      });
    }
  };

  // Función para agrupar archivos por carpeta
  const groupFilesByFolder = (files) => {
    const grouped = {};
    files.forEach((file) => {
      const parts = file.split("/");
      const folderName = parts.length > 1 ? parts[0] : "Archivos sin carpeta";
      if (!grouped[folderName]) grouped[folderName] = [];
      grouped[folderName].push(file);
    });
    return grouped;
  };

  // Cálculo de paginación: slice de los archivos a mostrar
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  // Se filtran los archivos por búsqueda (search) y luego se realiza la paginación
  const filteredFiles = files.filter((file) =>
    file.toLowerCase().includes(search.toLowerCase())
  );
  const currentFiles = filteredFiles.slice(indexOfFirstItem, indexOfLastItem);
  const groupedFiles = groupFilesByFolder(currentFiles);
  const totalPages = Math.ceil(filteredFiles.length / itemsPerPage);

  // Renderizado del control de paginación
  const renderPagination = () => {
    let items = [];
    for (let number = 1; number <= totalPages; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === currentPage}
          onClick={() => setCurrentPage(number)}
        >
          {number}
        </Pagination.Item>
      );
    }
    return (
      <Pagination>
        <Pagination.First onClick={() => setCurrentPage(1)} disabled={currentPage === 1} />
        <Pagination.Prev onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))} disabled={currentPage === 1} />
        {items}
        <Pagination.Next onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))} disabled={currentPage === totalPages} />
        <Pagination.Last onClick={() => setCurrentPage(totalPages)} disabled={currentPage === totalPages} />
      </Pagination>
    );
  };

  const handleUpload = async () => {
    if (!selectedFilesToUpload.length) return;
  
    const uploadPromises = selectedFilesToUpload.map((file) => {
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("folder", folder.trim());
  
        const xhr = new XMLHttpRequest();
        xhr.open(
          "POST",
          `${process.env.REACT_APP_API_URL}administrador/s3/files/${folder.trim()}/`,
          true
        );
        xhr.setRequestHeader("Authorization", `Bearer ${token}`);
        xhr.upload.onprogress = (event) => {
          if (event.lengthComputable) {
            setUploadProgress((prev) => ({
              ...prev,
              [file.name]: Math.round((event.loaded / event.total) * 100),
            }));
          }
        };
  
        xhr.onload = () => {
          if (xhr.status >= 200 && xhr.status < 300) {
            resolve();
          } else {
            reject(new Error("Upload failed"));
          }
        };
  
        xhr.onerror = () => reject(new Error("Upload failed"));
        xhr.send(formData);
      });
    });
  
    try {
      await Promise.all(uploadPromises);
      setSelectedFilesToUpload([]);
      setUploadProgress({});
      setAlert({
        show: true,
        message: "Files uploaded successfully",
        variant: "success",
      });
      fetchFiles(folder.trim());
    } catch (error) {
      setAlert({
        show: true,
        message: "Error uploading files",
        variant: "danger",
      });
    }
  };
  

  const handleDelete = async () => {
    if (!window.confirm("¿Está seguro de eliminar los archivos seleccionados?"))
      return;

    try {
      for (const file of selectedFiles) {
        await fetch(
          `${process.env.REACT_APP_API_URL}administrador/s3/files/?key=${encodeURIComponent(file)}`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }
      fetchFiles();
      setSelectedFiles([]);
      setAlert({
        show: true,
        message: "Files deleted successfully",
        variant: "success",
      });
    } catch (error) {
      setAlert({
        show: true,
        message: "Error deleting files",
        variant: "danger",
      });
    }
  };

  const handleDownload = async (fileName) => {
    try {
      const encodedFileName = encodeURIComponent(fileName);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}administrador/s3/download/?key=${encodedFileName}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) throw new Error("Error fetching download URL");

      const data = await response.json();
      console.log("Download URL:", data.download_url);

      if (!data.download_url) throw new Error("Invalid download URL");

      window.location.href = data.download_url;
    } catch (error) {
      console.error(error);
      setAlert({
        show: true,
        message: "Error downloading file",
        variant: "danger",
      });
    }
  };

  const handleFileSelection = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFilesToUpload(files);
  };

  return (
    <div>
      {alert.show && <Alert variant={alert.variant}>{alert.message}</Alert>}
      <Row className="mb-3">
        <Col xs={4}>
          <Form.Control
            type="text"
            placeholder="Folder name"
            value={folder}
            onChange={(e) => setFolder(e.target.value)}
          />
        </Col>
        <Col xs={2}>
          <Button variant="primary" onClick={() => fetchFiles(folder.trim())}>
            Buscar
          </Button>
        </Col>
        <Col xs={4}>
          <Form.Control type="file" multiple onChange={handleFileSelection} />
        </Col>
        <Col xs={2}>
          <Button
            variant="danger"
            onClick={handleDelete}
            //disabled={selectedFiles.length === 0}
            disabled
          >
            Delete Selected
          </Button>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <Form.Control
            type="text"
            placeholder="Search file"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </Col>
      </Row>

      {selectedFilesToUpload.length > 0 && (
        <div className="mb-3">
          <h5>Files to upload:</h5>
          <ul>
            {selectedFilesToUpload.map((file) => (
              <li key={file.name}>
                {file.name} - {uploadProgress[file.name] || 0}%
                <div
                  style={{
                    height: "5px",
                    width: "100%",
                    background: "#ddd",
                    marginTop: "5px",
                  }}
                >
                  <div
                    style={{
                      height: "5px",
                      width: `${uploadProgress[file.name] || 0}%`,
                      background: "green",
                    }}
                  ></div>
                </div>
              </li>
            ))}
          </ul>
          <Button onClick={handleUpload} variant="success">
            Upload Files
          </Button>
        </div>
      )}

      {/* Mostrar cantidad total de archivos */}
      <div className="mb-2">
        <strong>Total de archivos:</strong> {filteredFiles.length}
      </div>

      <TableContainer component={Paper} style={{ maxHeight: "500px", overflowY: "auto" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Select</TableCell>
              <TableCell>Folder</TableCell>
              <TableCell>File Name</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(groupedFiles).map(([folder, files]) => (
              <React.Fragment key={folder}>
                <TableRow style={{ backgroundColor: "#f1f1f1", fontWeight: "bold" }}>
                  <TableCell colSpan={4}>{folder}</TableCell>
                </TableRow>
                {files.map((file) => {
                  const fileName = file.split("/").pop();
                  return (
                    <TableRow key={file}>
                      <TableCell>
                        <Checkbox
                          checked={selectedFiles.includes(file)}
                          onChange={() =>
                            setSelectedFiles((prev) =>
                              prev.includes(file)
                                ? prev.filter((f) => f !== file)
                                : [...prev, file]
                            )
                          }
                        />
                      </TableCell>
                      <TableCell>{folder}</TableCell>
                      <TableCell>{fileName}</TableCell>
                      <TableCell>
                        <Button variant="primary" onClick={() => handleDownload(file)}>
                          Download
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Control de paginación */}
      <div className="d-flex justify-content-center mt-3">
        {renderPagination()}
      </div>
    </div>
  );
}

export default S3View;
