import React, { useEffect } from "react";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import { Button, Row, Col, Form, Alert, Modal } from "react-bootstrap";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as XLSX from 'xlsx';
import AADService from "../funcionesAuth.js";


//import AADService from "./funcionesAuth.js";
function createData(id, name, fat, carbs, protein, observacion_r) {
  return { id, name, fat, carbs, protein, observacion_r };
}

const rows = [
  createData("1", "Proveedor 1", "Bancolombia", 67, 4.3, "observacion"),
  createData("2", "Proveedor 2", "BAM", 51, 4.9, "observacion"),
  createData("3", "Proveedor 3", "BAM", 24, 6.0, "observacion"),
  createData("4", "Proveedor 4", "Bancolombia", 24, 4.0, "observacion"),
  createData("5", "Otra", "Bancolombia", 49, 3.9, "observacion"),
  createData("6", "Otra 1", "Bancolombia", 87, 6.5, "observacion"),
];

const headCells = [
  { id: "id", numeric: false, disablePadding: true, label: "Pregunta" },
  /*
  {
    id: "name",
    numeric: true,
    disablePadding: false,
    label: "Respuesta Usuario",
  },
  { id: "fat", numeric: true, disablePadding: false, label: "Observaciones" },
    */
  {
    id: "carbs",
    numeric: true,
    disablePadding: false,
    label: "Respuesta Riesgos",
  },

  {
    id: "protein",
    numeric: true,
    disablePadding: false,
    label: "Observaciones Riesgos",
  },
  /*
  {
    id: "observacion_r",
    numeric: true,
    disablePadding: false,
    label: "Usuario Consultado",
  },
  */
];

/*Encabezado de tabla*/
function EnhancedTableHead(props) {
  const { classes } = props;
  return (
    <TableHead>
      <TableRow>
        <TableCell
          style={{ backgroundColor: "#2c2a29", color: "#ffffff" }}
        ></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            style={{ backgroundColor: "#2c2a29", color: "#ffffff" }}
          >
            <label className='label'>{headCell.label}</label>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
};

/*Número de tablas seleccionadas y eliminar (parte de arriba de la tabla)*/

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    //marginBottom: theme.spacing(2),
  },
  container: {
    maxHeight: "65vh",
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

/*Mapeado de la tabla completa */
export default function NewCuestionario(props) {
  const classes = useStyles();
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(30);
  const proveedor = props.proveedor; // Contiene la información de los get cuestionario antioguo o nuevo.
  const ruta = props.rutaTipos;
  const [dataCarga, setDataCarga] = React.useState([]);
  const [statusRequest, setStatusRequest] = React.useState(0);
  const [display_analizar, setDisplayAnalizar] = React.useState(false); 
  const [cuestionario, setCuestionario] = React.useState(props.cuestionario);
  const [loading, setLoading] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const timer = React.useRef();
  const [progress, setProgress] = React.useState(10);
  const AzureADService = new AADService();
  const token = AzureADService.getToken();
  const [validRole, setValidRole] = React.useState(
    localStorage.getItem("rolusuario") == 2 ||
      localStorage.getItem("rolusuario") == 1
      ? true
      : false
  );

useEffect(() => {
}, []);

function ExportarExcel (data_cuestionario){
const newBook = XLSX.utils.book_new();

const firstItem = data_cuestionario[0];
if (!firstItem.hasOwnProperty('respuestariesgos')) {
  firstItem.respuestariesgos = '';
}
if (!firstItem.hasOwnProperty('observacionesriesgos')) {
  firstItem.observacionesriesgos = '';
}
data_cuestionario.forEach(function(json) {
  delete json.estado;
  delete json.respuestausuario;
  delete json.observaciones;
  delete json.tipo_despliegue;
  delete json.tipo_canal;
  delete json.tipo_pregunta;
  delete json.tipo_relacion;
  //delete json.idevaluacion;
  //delete json.idcuestionarioevaluacion;
  });
const sheet1 = XLSX.utils.json_to_sheet(data_cuestionario);
const range = XLSX.utils.decode_range(sheet1['!ref']);
const numColumns = range.e.c + 1; 
if (numColumns === 5) {
  //Caso de cuestionario nuevo
  if(!sheet1.D1.c) sheet1.D1.c = [];
  sheet1['!cols'] = [];
  sheet1['!cols'][0] = { hidden: true };
  sheet1['!cols'][1] = { hidden: true };
  sheet1.D1.c.push({t:"Digitar únicamente Si, No o No Aplica"});
  XLSX.utils.book_append_sheet(newBook, sheet1, "Cuestionario");
  XLSX.writeFile(newBook,"Nuevo_Cuestionario_" + "idevaluacion_" + localStorage.getItem("idevaluacion") + ".xlsx");
  } else {
  if (props.titulo === "Nuevo") {
    if(!sheet1.F1.c) sheet1.F1.c = [];
    sheet1['!cols'] = [];
    sheet1['!cols'][0] = { hidden: true };
    sheet1['!cols'][3] = { hidden: true };
    sheet1['!cols'][4] = { hidden: true };
    sheet1.F1.c.push({t:"Digitar únicamente Si, No o No Aplica"});
    XLSX.utils.book_append_sheet(newBook, sheet1, "Cuestionario");
  }
  else {
    if(!sheet1.F1.c) sheet1.F1.c = [];
    sheet1['!cols'] = [];
    sheet1['!cols'][0] = { hidden: true };
    sheet1['!cols'][1] = { hidden: true };
    sheet1['!cols'][2] = { hidden: true };
    sheet1['!cols'][4] = { hidden: true };
    sheet1['!cols'][5] = { hidden: true };
    sheet1.F1.c.push({t:"Digitar únicamente Si, No o No Aplica"});
    XLSX.utils.book_append_sheet(newBook, sheet1, "Cuestionario");
  }
  if (props.titulo === "Nuevo") {
  XLSX.writeFile(newBook,"Edición_Cuestionario_" + "idevaluacion_" + localStorage.getItem("idevaluacion") + ".xlsx");
  } else {
  XLSX.writeFile(newBook,"Cuestionario_Antiguo_" + "idevaluacion_" + localStorage.getItem("idevaluacion") + ".xlsx");
  }
}
}

const elegirArchivo = async (e) => {
const file = e.target.files[0];
const data = await file.arrayBuffer();
const workbook = XLSX.read(data);
const worksheet = workbook.Sheets[workbook.SheetNames[0]];
const jsondata = XLSX.utils.sheet_to_json(worksheet);
setDataCarga(jsondata);
}

const handleButtonClick = () =>{
   setShowModal(true);
};
const handleCloseModal = () => {
  setShowModal(false);
};

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };
  const isSelected = (id) => selected.indexOf(id) !== -1;
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  function redirect() {
    setLoading(true);
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 0 : prevProgress + 10
      );
    }, 800);
    let i;
    let respuestas_usuario = document.getElementsByClassName("respuesta_usuario");
    let observaciones = document.getElementsByClassName("observaciones");
    let usuario_consultado = document.getElementsByClassName("usuario_consultado");
    let respuesta_riesgos = document.getElementsByClassName("respuesta_riesgos");
    let observaciones_riesgos = document.getElementsByClassName("observaciones_riesgos");
    let idcuestionario = document.getElementsByClassName("encabezado_pregunta");
    let array_response = [];
    const timeElapsed = Date.now();
    const today = new Date(timeElapsed);
    if (cuestionario) {
      for (i = 0; i < observaciones_riesgos.length; i++) {
        array_response.push({
          idcuestionarioevaluacion: proveedor[i].idcuestionarioevaluacion,
          idevaluacion: parseInt(localStorage.getItem("idevaluacion"), 10),
          idcuestionario_tipoactivo: proveedor[i].idcuestionario_tipoactivo,
          respuestausuario: respuesta_riesgos[i].children[0].value,
          observaciones: observaciones_riesgos[i].children[0].value,
          usuarioconsultado: "-" /*usuario_consultado[i].children[0].value*/,
          //respuestariesgos: respuesta_riesgos[i].children[0].value,
          //observacionesriesgos: observaciones_riesgos[i].children[0].value,
          fechamodificacion: today.toISOString(),
          idusuariomodificacion: parseInt(
            localStorage.getItem("idusuario"),
            10
          ),
        });
        setDisplayAnalizar(true);
      }
      fetch(
          process.env.REACT_APP_API_URL +
          "evaluacion/newcuestionarioNew/" +
          localStorage.getItem("idevaluacion") +
          "/" + ruta,
        {
          method: "PUT",
          body: JSON.stringify({ cuestionarioEvaluacion: array_response }),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: "Bearer " + token,
          },
        }
      ).then((response) =>
        response.json().then((data) => {
          if (response.status >= 200 && response.status < 300) {
            setStatusRequest(2);
            setCuestionario(true);
            props.cuestionario_exist();
            //document.getElementById("guardar_cuestionario").disabled = true;
            setDisplayAnalizar(true);
          } else if (response.status >= 500) {
            setStatusRequest(5);
          } else if (response.status >= 400 && response.status < 500) {
            setStatusRequest(4);
          }
          setLoading(false);
        })
      );
    } else {
      for (i = 0; i < observaciones_riesgos.length; i++) {
        array_response.push({
          idcuestionarioevaluacion: 0,
          idevaluacion: parseInt(localStorage.getItem("idevaluacion"), 10),
          idcuestionario_tipoactivo: proveedor[i].idcuestionario_tipoactivo,
          respuestausuario: respuesta_riesgos[i].children[0].value, //respuestas_usuario[i].children[0].value,
          observaciones: observaciones_riesgos[i].children[0].value, //observaciones[i].children[0].value,
          usuarioconsultado: "-" /*usuario_consultado[i].children[0].value*/,
          //respuestariesgos: respuesta_riesgos[i].children[0].value,
          //observacionesriesgos: observaciones_riesgos[i].children[0].value,
          fechacreacion: today.toISOString(),
          idusuariocreacion: parseInt(localStorage.getItem("idusuario"), 10),
          fechamodificacion: today.toISOString(),
          idusuariomodificacion: parseInt(
            localStorage.getItem("idusuario"),
            10
          ),
          disp_varchar1: 1,
        });
      }
      fetch(
          process.env.REACT_APP_API_URL +
          "evaluacion/newcuestionarioNew/" +
          localStorage.getItem("idevaluacion") +
          "/" + props.rutaTipos,
        {
          method: "POST",
          body: JSON.stringify({ cuestionarioEvaluacion: array_response }),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: "Bearer " + token,
          },
        }
      ).then((response) =>
        response.json().then((data) => {
          if (response.status >= 200 && response.status < 300) {
            setStatusRequest(2);
            setCuestionario(true);
            //setProveedor(data);
            props.cuestionario_exist();
            //document.getElementById("guardar_cuestionario").disabled = true;
            setDisplayAnalizar(true);
          } else if (response.status >= 500) {
            setStatusRequest(5);
          } else if (response.status >= 400 && response.status < 500) {
            setStatusRequest(4);
          }
          setLoading(false);
        })
      );
    }
  }
  function cargarArchivo() {
    setLoading(true);
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 0 : prevProgress + 10
      );
    }, 800);
    let i;
    let datos_carga = dataCarga;
    if (datos_carga.length === 0) {
      alert("No se ha seleccionado un archivo")
      setLoading(false);
      return
    }
    let tipo_cuestionario = "";
    if (datos_carga[0].idcuestionarioevaluacion) {
      tipo_cuestionario = "Edicion"
    }
    else {
      tipo_cuestionario = "Nuevo"
    }
    let array_response = [];
    const timeElapsed = Date.now();
    const today = new Date(timeElapsed);
    if (cuestionario) {
      for (let i = 0; i < datos_carga.length; i++) {
        let respuestausuario = datos_carga[i].respuestariesgos
        let respuestariesgos = datos_carga[i].respuestariesgos
      
        if (respuestausuario!== "Si" && respuestausuario!== "No" && respuestausuario !== "No Aplica") {
          respuestausuario = "No Aplica";
        }
      
        if (respuestariesgos!== "Si" && respuestariesgos!== "No" && respuestariesgos !== "No Aplica") {
          respuestariesgos = "No Aplica";
        }
      
        array_response.push({
          idcuestionarioevaluacion: datos_carga[i].idcuestionarioevaluacion,
          idevaluacion: datos_carga[i].idevaluacion,
          idcuestionario_tipoactivo: datos_carga[i].idcuestionario_tipoactivo,
          respuestausuario: respuestausuario,
          observaciones: datos_carga[i].observacionesriesgos ? datos_carga[i].observacionesriesgos : null,
          usuarioconsultado: "-", 
          //respuestariesgos: respuestariesgos,
          //observacionesriesgos: datos_carga[i].observacionesriesgos ? datos_carga[i].observacionesriesgos : null,
          fechamodificacion: today.toISOString(),
          idusuariomodificacion: parseInt(localStorage.getItem("idusuario"), 10),
        });
        setDisplayAnalizar(true);
      }
      fetch(
          process.env.REACT_APP_API_URL +
          "evaluacion/newcuestionarioNew/" +
          localStorage.getItem("idevaluacion") +
          "/" + props.rutaTipos,
        {
          method: "PUT",
          body: JSON.stringify({ cuestionarioEvaluacion: array_response, tipo_cuestionario: tipo_cuestionario, titulo: props.titulo }),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: "Bearer " + token,
          },
        }
      ).then((response) =>
        response.json().then((data) => {
          if (response.status >= 200 && response.status < 300) {
            setStatusRequest(2);
            setCuestionario(true);
            setDisplayAnalizar(true);
            setShowModal(false);
            props.cuestionario_exist();
            alert("Edición exitosa")
          } else if (response.status >= 500) {
            setStatusRequest(5);
          } else if (response.status >= 400 && response.status < 500) {
            setStatusRequest(4);
            alert(data.message);
          }
          setLoading(false);
        })
      );
    } else {
      for (i = 0; i < datos_carga.length; i++) {
        let respuestausuario = datos_carga[i].respuestariesgos
        let respuestariesgos = datos_carga[i].respuestariesgos
      
        if (respuestausuario!== "Si" && respuestausuario!== "No" && respuestausuario !== "No Aplica") {
          respuestausuario = "No Aplica";
        }
      
        if (respuestariesgos!== "Si" && respuestariesgos!== "No" && respuestariesgos !== "No Aplica") {
          respuestariesgos = "No Aplica";
        }
        array_response.push({
          idcuestionarioevaluacion: 0,
          idevaluacion: parseInt(localStorage.getItem("idevaluacion"), 10),
          idcuestionario_tipoactivo: datos_carga[i].idcuestionario_tipoactivo,
          respuestausuario: respuestausuario,
          observaciones: datos_carga[i].observacionesriesgos ? datos_carga[i].observacionesriesgos : null,
          usuarioconsultado: "-" /*usuario_consultado[i].children[0].value*/,
          //respuestariesgos: respuestariesgos,
          //observacionesriesgos: datos_carga[i].observacionesriesgos ? datos_carga[i].observacionesriesgos : null,
          fechacreacion: today.toISOString(),
          idusuariocreacion: parseInt(localStorage.getItem("idusuario"), 10),
          fechamodificacion: today.toISOString(),
          idusuariomodificacion: parseInt(
            localStorage.getItem("idusuario"),
            10
          ),
          disp_varchar1: 1,
        });
      }
      fetch(
          process.env.REACT_APP_API_URL +
          "evaluacion/newcuestionarioNew/" +
          localStorage.getItem("idevaluacion") +
          "/" + props.rutaTipos,
        {
          method: "POST",
          body: JSON.stringify({ cuestionarioEvaluacion: array_response, tipo_cuestionario: tipo_cuestionario, titulo: props.titulo}),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: "Bearer " + token,
          },
        }
      ).then((response) =>
        response.json().then((data) => {
          if (response.status >= 200 && response.status < 300) {
            setStatusRequest(2);
            setCuestionario(true);
            //setProveedor(data);
            setDisplayAnalizar(true);
            setShowModal(false);
            props.cuestionario_exist();
            alert("¡RECUERDA!\nSi quieres volver a editar el cuestionario mediante un archivo EXCEL,\ndebes volver a descargar el cuestionario.");
            alert("Carga exitosa")
          } else if (response.status >= 500) {
            setStatusRequest(5);
          } else if (response.status >= 400 && response.status < 500) {
            setStatusRequest(4);
            alert(data.message);
          }
          setLoading(false);
        })
      );
      }
  }


  function CircularProgressWithLabel(props) {
    return (
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress variant='determinate' {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant='caption' component='div' color='text.secondary'>
            {`${Math.round(props.value)}%`}
          </Typography>
        </Box>
      </Box>
    );
  } 
  function analizar() {
    setLoading(true);
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 0 : prevProgress + 10
      );
    }, 800);
    fetch(process.env.REACT_APP_API_URL + "evaluacion/analizarCuestionario/", {
      method: "POST",
      body: JSON.stringify({
        idusuariocreacion: localStorage.getItem("idusuario"),
        idevaluacion: parseInt(localStorage.getItem("idevaluacion"), 10),
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          setStatusRequest(7);
        } else if (response.status >= 500) {
          setStatusRequest(5);
        } else if (response.status >= 400 && response.status < 500) {
          setStatusRequest(4);
        }
        setLoading(false);
      })
      .catch(function (err) {}); //response.status ->, response.text()
  }
  function AlertDismissibleExample({ alerta }) {
    if (alerta === 1) {
      return <Alert variant='warning'>Alerta</Alert>;
    } else if (alerta === 2) {
      return <Alert variant='success'>Guardó exitosamente</Alert>;
    } else if (alerta === 3) {
      return <Alert variant='danger'></Alert>;
    } else if (alerta === 4) {
      return <Alert variant='warning'>Error al enviar la información</Alert>;
    } else if (alerta === 5) {
      return <Alert variant='danger'>Error en el servidor</Alert>;
    } else if (alerta === 7) {
      return (
        <Alert variant='success'>
          Se activaron las vulnerabilidades no técnicas
        </Alert>
      );
    } else {
      return <p></p>;
    }
  }
  return (
    <div className={classes.root}>
      <AlertDismissibleExample alerta={statusRequest} />
      <Paper className={classes.paper}>
        <Row className='mb-1'>
          <Col sm={5}>
            <h3 className='subtitulo'> Cuestionario {props.titulo}</h3>
          </Col>
          <Col sm={1}>
            {loading && <CircularProgressWithLabel value={progress} />}
          </Col>
          {/*<Col sm={3}>
            {validRole ? (
              <Button
                className='botonPositivo'
                id='analizar_cuestionario'
                style={{
                  minWidth: "215px",
                  minHeight: "46px",
                  display: display_analizar ? "inline" : "none",
                }}
                onClick={() => analizar()}
              >
                Analizar Cuestionario
              </Button>
            ) : (
              ""
            )}

          </Col>*/}          

          <Col sm={3}>
          {validRole && props.existeTipoDespCanal ? (
              <Button
                className='botonIngreso'
                id='guardar_cuestionario'
                onClick={() => redirect()}
              >
                Guardar respuestas
              </Button>
            ) 
            : (
              ""
            )}
          </Col>

        </Row>

        <Row className='mb-2'>
          <Col>
          <h4 className='EspacioBlanco'></h4>
          </Col>
      

          <Col sm={3}>
          <Button
                className='botonDescargar'
                id='descargar_cuestionario'
                style= {{
                  minWidth: "215px",
                  minHeight: "46px",
                  
                  //display: display_analizar ? "inline" : "none"
              
                }}
                disabled
                onClick = {() => ExportarExcel(proveedor)}>
                Descargar cuestionario
              </Button>
          </Col>



          <Col sm={3}>
           {validRole && props.existeTipoDespCanal  ? (
          <Button
                className='botonCargar'
                id='Cargar_cuestionario'
                style= {{
                  minWidth: "215px",
                  minHeight: "46px",}}
                disabled
                onClick={handleButtonClick}
               >
                Cargar cuestionario
              </Button>  
              ) 
              : (
                ""
              )}

          <Modal show={showModal} onHide={handleCloseModal}>
            <Form></Form>
          <Modal.Header closeButton>
            <Modal.Title>Selecciona un archivo</Modal.Title>
          </Modal.Header>

          <Modal.Body>
          <input 
          className="Archivo" 
          id="inputCargar"
          type="file"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          name="files"
          onChange={(e) => elegirArchivo(e)}
          />
          </Modal.Body>

          <Modal.Footer>
          <Button
          type='submit'
          className='botonCargar2'
          style={{ minWidth: "20%" }}
          onClick={cargarArchivo}>
            Cargar y Guardar
          </Button>
            <Button className='botonCancelar' variant="secondary" 
            onClick={handleCloseModal}>
              Cancelar
            </Button>
          </Modal.Footer>
        </Modal>
    
            </Col>
      </Row>



        <TableContainer className={classes.container}>
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby='tableTitle'
            size={dense ? "small" : "medium"}
            aria-label='enhanced table'
          >
            <EnhancedTableHead
              classes={classes}
              style={{ fontStyle: "bold" }}
              numSelected={selected.length}
              rowCount={proveedor.length}
            />
            <TableBody>
              {proveedor.map((row, index) => {
                const isItemSelected = isSelected(
                  row.idcuestionario_tipoactivo
                );
                const opc = ["No Aplica", "Si", "No"];
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    onClick={(event) =>
                      handleClick(event, row.idcuestionario_tipoactivo)
                    }
                    role='checkbox'
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    className='encabezado_pregunta'
                    id={row.idcuestionario}
                    key={row.idcuestionario_tipoactivo}
                    selected={isItemSelected}
                  >
                    <TableCell 
                      padding='checkbox' 
                      className={props.titulo === 'Antiguo' ? 'opaco' : ''}
                    >
                      {row.idcuestionario}
                    </TableCell>
                    <TableCell
                      component='th'
                      id={labelId}
                      scope='row'
                      padding='none'
                      className={props.titulo === 'Antiguo' ? 'opaco' : ''}
                    >
                      {row.pregunta}
                    </TableCell>
                    <TableCell 
                      align='right' 
                      className={`respuesta_riesgos ${props.titulo === 'Antiguo' ? 'opaco' : ''}`}
                    >
                      <select className='form-control fuente-cuestionario'>
                        {row.respuestausuario !== null &&
                        row.respuestausuario !== undefined ? (
                          <>
                            <option>{row.respuestausuario}</option>
                            {opc.map((option) => {
                              return option !== row.respuestausuario ? (
                                <option>{option}</option>
                              ) : (
                                ""
                              );
                            })}
                          </>
                        ) : (
                          <>
                            {opc.map((option) => {
                              return <option>{option}</option>;
                            })}
                          </>
                        )}
                      </select>
                    </TableCell>
                    <TableCell 
                      align='right' 
                      className={`observaciones_riesgos ${props.titulo === 'Antiguo' ? 'opaco' : ''}`}
                      >
                      <textarea
                        className='form-control fuente-cuestionario'
                        defaultValue={row.observaciones !== null && row.observaciones !== undefined ? row.observaciones : ""}
                      ></textarea>
                    </TableCell>
                    {/*
                      <TableCell align="right" className="usuario_consultado">
                        <input className="form-control fuente-cuestionario" type="text"></input>
                      </TableCell>
              */}
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
