import React, { useEffect, useRef } from "react";
import * as echarts from "echarts";
import { TooltipComponent, GridComponent } from "echarts/components";
import { BarChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([TooltipComponent, GridComponent, BarChart, CanvasRenderer]);

const HeatMapChartComponent = ({ datos }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (!chartRef.current || !datos || !datos.columns || !datos.rows || !datos.values) return;
  
    const myChart = echarts.init(chartRef.current);
  
    const vulnerabilidades = [
      { id: 284, nombre: "Control\nde acceso\ninadecuado" },
      { id: 435, nombre: "Interacción\nindebida\nentre varias\nentidades" },
      { id: 664, nombre: "Control\nindebido\nde un recurso" },
      { id: 682, nombre: "Cálculo\nincorrecto" },
      { id: 691, nombre: "Gestión\ninsuficiente del\nflujo de\ncontrol" },
      { id: 693, nombre: "Fallo del\nmecanismo\nde protección" },
      { id: 697, nombre: "Comparación\nincorrecta" },
      { id: 703, nombre: "Comprobación\nincorrecta\nde condiciones" },
      { id: 707, nombre: "Neutralización\nincorrecta" },
      { id: 710, nombre: "Incumplimiento\nde normas\nde codificación" },
    ];
  
    const amenazas = [
      { id: 156, nombre: "Participar en\ninteracciones\nengañosas" },
      { id: 210, nombre: "Abusar de\nfuncionalidad\nexistente" },
      { id: 255, nombre: "Manipular\nestructuras\nde datos" },
      { id: 262, nombre: "Manipular\nrecursos\ndel sistema" },
      { id: 152, nombre: "Inyectar\nelementos\ninesperados" },
      { id: 223, nombre: "Emplear\ntécnicas\nprobabilísticas" },
      { id: 172, nombre: "Manipular la\nsincronización\ny el estado" },
      { id: 118, nombre: "Recopilar y\nanalizar\ninformación" },
      { id: 225, nombre: "Alterar el\ncontrol\nde acceso" },
    ];
  
    // Convertir IDs a nombres
    const hours = datos.columns.map(id => 
      amenazas.find(a => a.id === Number(id))?.nombre || id
    );
    const days = datos.rows.map(id => 
      vulnerabilidades.find(v => v.id === Number(id))?.nombre || id
    );
  
    const data = datos.values;
  
    const option = {
      tooltip: {
        position: "top",
      },
      grid: {
        height: "50%",
        top: "10%",
      },
      xAxis: {
        type: "category",
        data: hours,
        splitArea: {
          show: true,
        },
      },
      yAxis: {
        type: "category",
        data: days,
        splitArea: {
          show: true,
        },
      },
      visualMap: {
        min: 0,
        max: 100,
        calculable: true,
        orient: "horizontal",
        left: "center",
        bottom: "15%",
      },
      series: [
        {
          type: "heatmap",
          data: data,
          label: {
            show: true,
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    };
  
    myChart.setOption(option);
    window.addEventListener("resize", () => myChart.resize());
  
    return () => {
      window.removeEventListener("resize", () => myChart.resize());
      myChart.dispose();
    };
  }, [datos]);

  return (
    <div>
      <div ref={chartRef} style={{ width: "75vw", height: "75vh" }}></div>
    </div>
  );
};

export default HeatMapChartComponent;
