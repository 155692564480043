import React, { useEffect, useRef, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import TableContainer from "@material-ui/core/TableContainer";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Tooltip,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { HelpOutline } from "@material-ui/icons";
import { Row, Col, Button, Alert } from "react-bootstrap";
import Select from "react-select";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import { DecryptData } from "../DatosEncriptados/EncryptedFunctions";
import CheckRole from "../CheckRole";
import StackedLineInherenteChart from "./StackedLineInherenteChart.js";
import StackedLineResidualChart from "./StackedLineResidualChart.js";
import StackedLineEfectividadChart from "./StackedLineEfectividadChart.js";
import CircularProgress from "@mui/material/CircularProgress";
import MarimekkoChartComponent from "./MarimekkoChartComponent.js";
import AADService from "../funcionesAuth.js";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Box,
} from "@mui/material";
function AlertDismissibleExample({ alerta }) {
  if (alerta === 1) {
    return <Alert variant="warning">Alerta</Alert>;
  } else if (alerta === 2) {
    return <Alert variant="success">Guardó exitosamente</Alert>;
  } else if (alerta === 3) {
    return <Alert variant="danger"></Alert>;
  } else if (alerta === 4) {
    return <Alert variant="warning">Error al enviar la información</Alert>;
  } else if (alerta === 5) {
    return <Alert variant="danger">Error en el servidor</Alert>;
  } else if (alerta === 6) {
    return (
      <Alert variant="warning">
        Ya existe una evaluación para el activo seleccionado
      </Alert>
    );
  } else {
    return <p></p>;
  }
}

const ActivateDesactivateModal = ({
  open,
  onClose,
  modalClasses,
  changeEvaluationStatus,
  setEvaluacion,
  evaluacion,
}) => {
  return (
    <Dialog open={open} sx={{ borderRadius: "10px" }}>
      <DialogTitle>
        {modalClasses === "activate"
          ? "La evaluación está desactivada"
          : "La evaluación está activada"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {modalClasses === "activate"
            ? "¿Está seguro que desea activar la evaluación?"
            : "¿Está seguro que desea desactivar la evaluación?"}
        </DialogContentText>
      </DialogContent>
      <Box display="flex" justifyContent="center">
        <DialogActions>
          <Button
            className="botonGeneral"
            onClick={() => {
              setEvaluacion({
                ...evaluacion,
                estado_evaluacion: true ? modalClasses === "activate" : false,
              });
              changeEvaluationStatus();
              onClose();
            }}
          >
            Entendido
          </Button>
        </DialogActions>
        <DialogActions>
          <Button className="botonGeneral" onClick={onClose}>
            Cancelar
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

const EditarEvaluacion = () => {
  const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
  });

  const getColor = (index) => {
    const colors = [
      "#00C587",
      "#9F62D2",
      "#01CDEB",
      "#FF803A",
      "#2C2A29",
      "#FFB8D2",
    ];
    return colors[index % colors.length];
  };
  const [loading, setLoading] = React.useState(false);
  const [evaluacion, setEvaluacion] = useState([]);
  const [riesgos, setRiesgos] = useState([]);
  const [historicoinherente, setHistoricoInherente] = useState([]);
  const [historicoresidual, setHistoricoResidual] = useState([]);
  const [historicoefectividad, setHistoricoEfectividad] = useState([]);
  const [risk, setRisk] = useState("residual");
  const check = new CheckRole("/EditarEvaluacion");
  const [OpcionesGenerales, setOpcionesGenerales] = useState([]);
  const [currentRole, setCurrentRole] = useState(0);
  const [fileExists, setFileExists] = useState(false);
  const [fileUrl, setFileUrl] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [analista_riesgos, setAnalistaRiesgos] = useState([]);
  const [dataActivo, setDataActivo] = useState([]);
  const idEvaluacion = localStorage.getItem("idevaluacion");
  const [idresponsable_TI, setIdResponsableTI] = useState(0);
  const [idresponsable_negocio, setIdResponsableNegocio] = useState(0);
  const [showHistorico, setShowHistorico] = useState(true);
  const [statusRequest, setStatusRequest] = useState(0);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [modalClasses, setModalClasses] = useState("activate");
  const AzureADService = new AADService();
  const token = AzureADService.getToken();

  const fetchDetalleEvaluacion = async () => {
    setLoading(true);
    const result = await fetch(
      process.env.REACT_APP_API_URL +
        "evaluacion/newdetalleevaluacion/" +
        localStorage.getItem("idcompania") +
        "/" +
        localStorage.getItem("idevaluacion") +
        "/",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );
    if (result.status >= 200 && result.status <= 300) {
      let data = await result.json();
      const { tipo_despliegue, tipo_canal, old_idtipo_activo } = data;
      tipo_despliegue
        ? localStorage.setItem("variable_tipo_despliegue", tipo_despliegue)
        : localStorage.removeItem("variable_tipo_despliegue");
      tipo_canal
        ? localStorage.setItem("variable_tipo_canal", tipo_canal)
        : localStorage.removeItem("variable_tipo_canal");
      old_idtipo_activo
        ? localStorage.setItem("variable_old_tipo_activo", old_idtipo_activo)
        : localStorage.removeItem("variable_old_tipo_activo");
      let dataCanal,
        dataDespliegue = "";
      if (!tipo_despliegue && !tipo_canal) {
        //Si dataStorageTipoDesp-canal no existe
        dataCanal = "No asociado";
        dataDespliegue = "No asociado";
      } //Si localStorage.getItem("variable_tipo_despliegue") contiene una ","
      else if (tipo_despliegue.includes(",")) {
        const Stipo_despliegue = tipo_despliegue.split(",");
        dataDespliegue = Stipo_despliegue.join(", ");
        dataCanal = tipo_canal;
      } else {
        dataDespliegue = tipo_despliegue;
        dataCanal = tipo_canal;
      }
      setEvaluacion({
        ...data,
        tipo_canal: dataCanal,
        tipo_despliegue: dataDespliegue,
      });

      let datos = data;
      if (datos.validadorero == "TI") {
        fetchUsuarioTI(datos.responsableti);
        fetchUsuarioNeg(datos.responsablenegocio);
      } else if (datos.validadorero == "Negocio") {
        fetchUsuarioNeg(datos.responsablenegocio);
        fetchUsuarioTI(datos.responsableti);
      } else {
        fetchUsuarioTI(datos.responsableti);
        fetchUsuarioNeg(datos.responsablenegocio);
      }
      setLoading(false);
      return data;
    } else if (result.status >= 400 && result.status <= 500) {
      return result.status;
    }
  };

  const updateFindings = async () => {
    const result = await fetch(
      process.env.REACT_APP_API_URL +
        "maestro/findings/update/" +
        localStorage.getItem("idactivo") +
        "/FILE/",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );
    if (result.status >= 200 && result.status <= 300) {
      let data = await result.json();
      alert(data['message']);
    } else if (result.status >= 400 && result.status <= 500) {
      alert("No se encontraron findings en esta evaluación");
    }
  };

  const Ontologia = async () => {
    const result = await fetch(
      process.env.REACT_APP_API_URL +
        "maestro/modeloAmenaza/" +
        localStorage.getItem("idevaluacion") +
        "/",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );
    if (result.status >= 200 && result.status <= 300) {
      alert("Ontología actualizada correctamente");
    } else if (result.status == 409) {
      alert("Las vulnerabilidades no tienen cve asociado");
    } else if (result.status >= 400 && result.status <= 500) {
      alert("Error al actualizar la ontología");
    }
  };

  //const fetchHistoricoEvaluacion = async () => {
  //  try {
  //    const result = await fetch(
  //      process.env.REACT_APP_API_URL +
  //        "evaluacion/historico/" +
  //        22 +
  //        "/",
  //      {
  //        method: "GET",
  //        headers: {
  //          Accept: "application/json",
  //          Authorization: "Bearer " + token,
  //        },
  //      }
  //    );
//
  //    if (result.status === 200) {
  //      const data = await result.json();
//
  //      const dataihnerente = data.map((item) => {
  //        return {
  //          idriesgo: item.idriesgo_id,
  //          exposicioninherentep95: item.exposicioninherentep95,
  //          fechaCreacion: item.fechacreacion,
  //        };
  //      });
//
  //      const dataresidual = data.map((item) => {
  //        return {
  //          idriesgo: item.idriesgo_id,
  //          exposicionresidual: item.exposicionresidual,
  //          fechaCreacion: item.fechacreacion,
  //        };
  //      });
//
  //      const dataefectividad = data.map((item) => {
  //        return {
  //          idriesgo: item.idriesgo_id,
  //          efectividadcontrol: item.efectividadcontrol,
  //          fechaCreacion: item.fechacreacion,
  //        };
  //      });
//
  //      setHistoricoInherente(dataihnerente);
  //      setHistoricoResidual(dataresidual);
  //      setHistoricoEfectividad(dataefectividad);
  //    } else {
  //      setShowHistorico(false);
  //      setHistoricoInherente([]);
  //      setHistoricoResidual([]);
  //      setHistoricoEfectividad([]);
  //    }
  //  } catch (error) {
  //    console.error("Error fetching historico evaluacion:", error);
  //    setHistoricoInherente([]);
  //    setHistoricoResidual([]);
  //    setHistoricoEfectividad([]);
  //  }
  //};

  const fetchRiesgosEvaluacion = async () => {
    const result = await fetch(
      process.env.REACT_APP_API_URL +
        "evaluacion/newriesgos/" +
        localStorage.getItem("idevaluacion") +
        "/",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );
    if (result.status >= 200 && result.status <= 300) {
      let data = await result.json();
      data.sort((a, b) => a.idriesgo - b.idriesgo);
      setRiesgos(data);
    } else {
      setRiesgos([]);
    }
  };

  const fetchUsuario = async () => {
    const result = await fetch(
      process.env.REACT_APP_API_URL +
        "usuarios/menu/" +
        localStorage.getItem("usuario") +
        "/",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );
    const encryptedUser = await result.json();
    const decryptedUser = DecryptData(encryptedUser);
    let usuario = JSON.parse(decryptedUser);
    let opciones = [];
    let permisos = [];
    for (let i = 0; i < usuario.length; i++) {
      permisos = usuario[i].permisos.split(";");
      opciones.push({ opcion: usuario[i].url_opcion, permisos: permisos });
    }
    setOpcionesGenerales(opciones);
    setCurrentRole(usuario[0].idrol);
  };

  // const fetchAdjuntos = async () => {
  //   const url = `${
  //     process.env.REACT_APP_API_URL
  //   }administrador/adjuntos/ERO/${localStorage.getItem("idevaluacion")}/`;
  //   try {
  //     const response = await fetch(url, {
  //       method: "GET",
  //       headers: {
  //         //'Authorization': "Bearer " + token,
  //       },
  //     });
  //     if (response.ok) {
  //       setFileExists(true);
  //       const blob = await response.blob();
  //       const contentDisposition = response.headers.get("Content-Disposition");
  //       const fileNameMatch =
  //         contentDisposition && contentDisposition.match(/filename="([^"]+)"/);
  //       const fileName = fileNameMatch
  //         ? fileNameMatch[1]
  //         : `ERO_${localStorage.getItem("idevaluacion")}`;
  //       const fileNameEncoded = encodeURIComponent(fileName);
  //       const downloadUrl = window.URL.createObjectURL(blob);
  //       setFileUrl(downloadUrl);
  //       setFileName(fileNameEncoded);
  //     } else {
  //       setFileExists(false);
  //       setFileUrl(null);
  //       console.error("File not found");
  //     }
  //   } catch (error) {
  //     console.error("Error checking file existence:", error);
  //   }
  // };

  const fetchUsuariosRoti = async () => {
    const result = await fetch(
      process.env.REACT_APP_API_URL + "usuariosroti/",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );
    let param = await result.json();
    setAnalistaRiesgos(param);
  };

  const fetchUsuarioTI = async (posicion) => {
    const resultTI = await fetch(
      process.env.REACT_APP_API_URL +
        "usuariosrol/" +
        localStorage.getItem("idcompania") +
        "/5/",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );

    let paramTI = await resultTI.json();
    let usuario = paramTI.filter((u) => u.idposicion == posicion);
    try {
      if (usuario[0].idposicion == localStorage.getItem("idposicion")) {
        setIdResponsableTI(usuario[0].idposicion);
      } else {
        setIdResponsableTI(0);
      }
    } catch {
      setIdResponsableTI(0);
    }
  };

  const fetchUsuarioNeg = async (posicion) => {
    const resultNeg = await fetch(
      process.env.REACT_APP_API_URL +
        "usuariosrol/" +
        localStorage.getItem("idcompania") +
        "/3/",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );
    let paramNeg = await resultNeg.json();
    let usuario = paramNeg.filter((u) => u.idposicion == posicion);
    try {
      if (usuario[0].idposicion == localStorage.getItem("idposicion")) {
        setIdResponsableNegocio(usuario[0].idposicion);
      } else {
        setIdResponsableNegocio(0);
      }
    } catch {
      setIdResponsableNegocio(0);
    }
  };

  const CambioEstadoFlujo = (cambio) => {
    let timeElapsed = Date.now();
    let today = new Date(timeElapsed);

    let data;

    if (cambio === "Aprobada") {
      data = JSON.stringify({
        idevaluacion: evaluacion.idevaluacion,
        estado_flujo: "Aprobada",
        fechamodificacion: today.toISOString(),
        idusuariomodificacion: parseInt(localStorage.getItem("idusuario"), 10),
        fechaaprobacion: today.toISOString(),
      });
    } else if (cambio === "Pendiente Aprobación") {
      data = JSON.stringify({
        idevaluacion: evaluacion.idevaluacion,
        estado_flujo: "Pendiente Aprobación",
        fechaenvio: today.toISOString(),
        fechamodificacion: today.toISOString(),
        idusuariomodificacion: parseInt(localStorage.getItem("idusuario"), 10),
      });
    } else {
      data = JSON.stringify({
        idevaluacion: evaluacion.idevaluacion,
        estado_flujo: "En proceso",
        fechamodificacion: today.toISOString(),
        idusuariomodificacion: parseInt(localStorage.getItem("idusuario"), 10),
      });
    }

    fetch(
      process.env.REACT_APP_API_URL +
        "evaluacion/cambioestadoflujo/" +
        localStorage.getItem("idevaluacion") +
        "/",
      {
        method: "PUT",
        body: data,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      }
    ).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        setStatusRequest(2);
      } else if (response.status >= 500) {
        setStatusRequest(5);
      } else if (response.status >= 400 && response.status < 500) {
        setStatusRequest(4);
      }
    });
  };

  const changeEvaluationStatus = (submit) => {
    let estado_evaluacion = true ? modalClasses === "activate" : false;
    let nombre_validador = document.getElementById("nombre_validador").value;
    let analista = document.getElementById("analista_riesgos");
    let idanalista = analista.options[analista.selectedIndex].value;
    let timeElapsed = Date.now();
    let today = new Date(timeElapsed);
    let data;

    if (submit === "submit") {
      data = JSON.stringify({
        idevaluacion: evaluacion.idevaluacion,
        estado_evaluacion: estado_evaluacion,
        idanalista: parseInt(idanalista, 10),
        validadorero: nombre_validador,
        fechamodificacion: today.toISOString(),
        idusuariomodificacion: parseInt(localStorage.getItem("idusuario"), 10),
      });
    } else {
      data = JSON.stringify({
        idevaluacion: evaluacion.idevaluacion,
        estado_evaluacion: estado_evaluacion,
        fechamodificacion: today.toISOString(),
        idusuariomodificacion: parseInt(localStorage.getItem("idusuario"), 10),
      });
    }
    fetch(
      process.env.REACT_APP_API_URL +
        "evaluacion/cambioestadoflujo/" +
        localStorage.getItem("idevaluacion") +
        "/",
      {
        method: "PUT",
        body: data,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      }
    ).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        setStatusRequest(2);
      } else if (response.status >= 500) {
        setStatusRequest(5);
      } else if (response.status >= 400 && response.status < 500) {
        setStatusRequest(4);
      }
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      const evaluacionData = await fetchDetalleEvaluacion();
      localStorage.setItem(
        "variable_tipo_activo",
        evaluacionData.idtipo_activo
      );
      const result2 = await fetch(
        process.env.REACT_APP_API_URL +
          "activoInformacion/" +
          evaluacionData.idactivo +
          "/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      const encryptedparam = await result2.json();
      const decryptedparam = DecryptData(encryptedparam);
      let param = JSON.parse(decryptedparam);
      setDataActivo(param);
      localStorage.setItem("alcance_corporativo", param.alcance_corporativo);
      localStorage.setItem("id_activo", evaluacionData.idactivo);
    };
    fetchData();
    //fetchHistoricoEvaluacion();
    fetchRiesgosEvaluacion();
    fetchUsuario();
    // fetchAdjuntos();
    fetchUsuariosRoti();
    fetchUsuarioTI(5);
    fetchUsuarioNeg(3);
  }, []);

  const handleShow = () => {
    setShowModal(true);
  };

  let opciones_validadorero = ["Negocio", "TI", "Mixto"];

  return (
    <div>
      <AlertDismissibleExample alerta={statusRequest} />
      {loading ? (
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div>
          <Row className="row_n_eval">
            <Col md={4}>
              <h2 className="estilo_titulos">Nueva Evaluación de Riesgos</h2>
            </Col>
            <Col md={3}>
              {/* {idEvaluacion != 0 ? (
                <>
                  {currentRole == 2 &&
                  evaluacion.estado_flujo == "En proceso" ? (
                    <Button
                      className="botonPositivo"
                      disabled
                      onClick={() => {
                        setEvaluacion({
                          ...evaluacion,
                          estado_flujo: "Pendiente Aprobación",
                        });
                        CambioEstadoFlujo("Pendiente Aprobación");
                      }}
                    >
                      Solicitar Validación
                    </Button>
                  ) : (
                    <>
                      {currentRole == 2 &&
                      evaluacion.estado_flujo == "Aprobada" ? (
                        <Button
                          className="botonPositivo"
                          disabled
                          onClick={() => {
                            setEvaluacion({
                              ...evaluacion,
                              estado_flujo: "En proceso",
                            });
                            CambioEstadoFlujo("En proceso");
                          }}
                        >
                          Evaluar de nuevo
                        </Button>
                      ) : (
                        <>
                          {(idresponsable_negocio ==
                            dataActivo.idposicionresponsablenegocio ||
                            idresponsable_TI ==
                              dataActivo.idposicionresponsableti) &&
                          evaluacion.estado_flujo == "Pendiente Aprobación" ? (
                            <Button
                              className="botonPositivo"
                              disabled
                              onClick={() => {
                                setEvaluacion({
                                  ...evaluacion,
                                  estado_flujo: "Aprobada",
                                });
                                CambioEstadoFlujo("Aprobada");
                              }}
                            >
                              Validar Evaluación
                            </Button>
                          ) : (
                            ""
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              ) : (
                ""
              )} */}
            </Col>
            <Col md={2}>
              {currentRole == 1 ? (
                <Button
                  className="botonGeneral"
                  onClick={() => {
                    updateFindings();
                  }}
                >
                  Actualizar Findings
                </Button>
              ) : (
                ""
              )}
            </Col>
            <Col md={1}>{""}</Col>
            <Col md={2}>
              {currentRole == 1 ? (
                <Button
                  className="botonGeneral"
                  onClick={() => {
                    Ontologia();
                  }}
                >
                  Actualizar Ontología
                </Button>
              ) : (
                ""
              )}
            </Col>
          </Row>
          <hr />
          <Row className="row_n_eval">
            <Col md={3}>
              <label className="label">Id Evaluación</label>
            </Col>
            <Col md={3}>
              <label className="texto">{evaluacion.idevaluacion}</label>
            </Col>
            <Col md={3}>
              <label className="label">Id Activo</label>
            </Col>
            <Col md={3}>
              <label className="texto">{evaluacion.idactivo}</label>
            </Col>
          </Row>
          <Row className="row_n_eval">
            <Col md={3}>
              <label className="label">Activo a evaluar</label>
            </Col>
            <Col md={3}>
              <label className="texto">{evaluacion.nombre}</label>
            </Col>
            <Col md={3}>
              <label className="label">Código App</label>
            </Col>
            <Col md={3}>
              <label className="texto">{evaluacion.elemento_configuracion}</label>
            </Col>
          </Row>
          <Row className="row_n_eval">
            <Col md={3}>
              <label className="label">Estado de la Evaluación</label>
            </Col>
            <Col md={3} className="d-flex">
              <label className="label">
                {evaluacion.estado_evaluacion === 1
                  ? "Activa"
                  : evaluacion.estado_evaluacion === 2
                  ? "Inactiva"
                  : ""}
              </label>
              {/* <Col>
              <label className="texto">
                {evaluacion.estado_evaluacion === true ? "Activa" : "Inactivo"}
              </label>
            </Col> */}
              {/* <Col>
              {(currentRole === 1 || currentRole === 2) &&
                evaluacion.estado_evaluacion === false &&
                parseInt(localStorage.getItem("idevaluacion")) !== 0 && (
                  <Button
                    id="guardar_evaluacion"
                    type="submit"
                    style={{
                      backgroundColor: "#00c389 ",
                      borderColor: "#00c389",
                    }}
                    onClick={() => {
                      if (evaluacion.estado_evaluacion === false) {
                        setModalClasses("activate");
                        setOpenEditModal(true);
                      }
                    }}
                  >
                    {"Activar"}
                  </Button>
                )}
              {(currentRole === 1 || currentRole === 2) &&
                evaluacion.estado_evaluacion === true &&
                parseInt(localStorage.getItem("idevaluacion")) !== 0 && (
                  <Button
                    id="guardar_evaluacion"
                    type="submit"
                    style={{
                      backgroundColor: "#00c389 ",
                      borderColor: "#00c389",
                    }}
                    onClick={() => {
                      if (evaluacion.estado_evaluacion === true) {
                        localStorage.setItem("estado_evaluacion", "false");
                        setModalClasses("desactivate");
                        setOpenEditModal(true);
                      }
                    }}
                  >
                    {"Desactivar"}
                  </Button>
                )}
            </Col> */}
            </Col>
            <Col md={3}>
              <label className="label">Tipo de activo a evaluar</label>
            </Col>
            <Col md={3}>
              <label className="texto">{evaluacion.tipo_activo}</label>
            </Col>
          </Row>
          <Row className="row_n_eval">
            <Col md={3}>
              <label className="label">Fecha de Creación</label>
            </Col>
            <Col md={3}>
              <label className="texto">{evaluacion.fechacreacion}</label>
            </Col>
            <Col md={3}>
              <label className="label">Tipo de Despliegue</label>
            </Col>
            <Col md={3}>
              <label className="texto">{evaluacion.tipo_despliegue}</label>
            </Col>
          </Row>
          <Row className="row_n_eval">
            <Col md={3}>
              <label className="label">Fecha de Modificación</label>
            </Col>
            <Col md={3}>
              <label className="texto">
                {evaluacion.fechamodificacion
                  ? evaluacion.fechamodificacion.split("T")[0]
                  : ""}
              </label>
            </Col>
            <Col md={3}>
              <label className="label">Estado del flujo</label>
            </Col>
            <Col md={3}>
              <label className="texto">
                {evaluacion.estado_flujo === "Pendiente Aprobación"
                  ? "Pendiente Validación"
                  : evaluacion.estado_flujo === "Aprobada"
                  ? "Validada"
                  : "En Proceso"}
              </label>
            </Col>
          </Row>
          <Row className="row_n_eval">
            <Col md={3}>
              <label className="label">¿Es BIA?</label>
            </Col>
            <Col md={3}>
              <label className="texto">{dataActivo.bia ? "Sí" : "No"}</label>
            </Col>
            <Col md={3}>
              <label className="label">¿Es SOX?</label>
            </Col>
            <Col md={3}>
              <label className="texto">{dataActivo.sox ? "Sí" : "No"}</label>
            </Col>
          </Row>
          <Row className="row_n_eval">
            <Col md={3}>
              <label className="label">Tipo de Canal</label>
            </Col>
            <Col md={3}>
              <label className="texto">{evaluacion.tipo_canal}</label>
            </Col>
            <Col md={3}>
              <label className="label">Evidencia de validación</label>
            </Col>
            <Col md={3}>
              <Button
                onClick={handleShow}
                disabled={
                  (currentRole !== 1 && currentRole !== 2) ||
                  evaluacion.estado_flujo !== "Pendiente Aprobación"
                }
              >
                Cargar
              </Button>
            </Col>
            <Col md={3}>
              {fileUrl && (
                <a href={fileUrl} download={fileName}>
                  {decodeURIComponent(fileName)}
                </a>
              )}
            </Col>
          </Row>
          <hr />
          <Row className="row_n_eval">
            <Col md={3}>
              <label className="label">Analista de riesgos</label>
            </Col>
            <Col md={3}>
              <select
                className="form-control"
                title="analista riesgos"
                id="analista_riesgos"
                value={evaluacion.analista_riesgos || ""}
                onChange={(e) => {
                  setEvaluacion({
                    ...evaluacion,
                    analista_riesgos: e.target.value,
                  });
                }}
              >
                <option value="" disabled>
                  Analista No Definido
                </option>
                {analista_riesgos.map((item) => (
                  <option key={item.idusuario} value={item.idusuario}>
                    {item.nombre}
                  </option>
                ))}
              </select>
            </Col>
            <Col md={3}>
              <label className="label">Validador ERO</label>
            </Col>
            <Col md={3}>
              <select
                className="form-control"
                title="Tipo de activo"
                id="nombre_validador"
                value={evaluacion.validadorero || ""}
                onChange={(e) => {
                  setEvaluacion({
                    ...evaluacion,
                    validadorero: e.target.value,
                  });
                }}
              >
                {opciones_validadorero.map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </Col>
          </Row>
          <Row className="row_n_eval">
            <Col md={3}>
              <label className="label">Responsable del Negocio</label>
            </Col>
            <Col md={3}>
              <label id="responsable_negocio" className="texto">
                {evaluacion.responsablenegocio}
              </label>
            </Col>
            <Col md={3}>
              <label className="label">Responsable en TI</label>
            </Col>
            <Col md={3}>
              <label id="responsable_TI" className="texto">
                {evaluacion.responsableti}
              </label>
            </Col>
          </Row>
          {dataActivo.alcance_corporativo == "1" ? (
            <Row className="mb">
              <Col className="text-center">
                <p className="label forn-label" style={{ color: "red" }}>
                  El activo a evaluar es de alcance corporativo, recuerde
                  socializar con los equipos ROTIC de los países
                </p>
              </Col>
            </Row>
          ) : null}
          <Row className="clasicenter" style={{ marginTop: "4%" }}>
            <Col md={3}></Col>

            <Col md={3}>
              {check.checkPermits(OpcionesGenerales, "PUT") ? (
                <Button
                  className="botonPositivo"
                  id="guardar_evaluacion"
                  type="submit"
                  style={{ width: "100%" }}
                  onClick={() => {
                    changeEvaluationStatus("submit");
                  }}
                >
                  Guardar
                </Button>
              ) : (
                ""
              )}
            </Col>
          </Row>
          <br />
          <hr />
          <Row className="row_n_eval">
            <Col md={6}>
              <h2 className="estilo_titulos">Resumen de riesgos</h2>
            </Col>
          </Row>
          <Paper>
            <TableContainer>
              <Table
                stickyHeader
                aria-labelledby="tableTitle"
                size={"medium"}
                aria-label="enhanced table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>ID Riesgo</TableCell>
                    <TableCell>Evento</TableCell>
                    <TableCell>Característica de seguridad</TableCell>
                    <TableCell>Riesgo Inherente</TableCell>
                    <TableCell>Efectividad Control</TableCell>
                    <TableCell>Riesgo Residual</TableCell>
                    <TableCell>Criticidad</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {riesgos.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <div
                          style={{
                            backgroundColor: getColor(index),
                            width: 20,
                            height: 20,
                            borderRadius: "50%",
                          }}
                        />
                      </TableCell>
                      <TableCell>{row.idriesgo}</TableCell>
                      <TableCell>{row.evento}</TableCell>
                      <TableCell>{row.caracteristica_seguridad}</TableCell>
                      <TableCell>{row.exposicioninherentep95}</TableCell>
                      <TableCell>
                        {row.efectividadcontrolmanual
                          ? row.efectividadcontrolmanual
                          : row.efectividadcontrol}
                      </TableCell>
                      <TableCell>{row.exposicionresidual}</TableCell>
                      <TableCell>{row.nivelriesgo}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <br />
          <hr />
          <br />
          <Row>
            <Col sm={12} xs={12}>
              <MarimekkoChartComponent />
            </Col>
          </Row>
          <br />
          <hr />
          <br />
      {/* {showHistorico ? (
            <>
              <Row>
                <Select
                  options={[
                    { value: "inherente", label: "Inherente" },
                    { value: "residual", label: "Residual" },
                  ]}
                  placeholder="Seleccione tipo"
                  onChange={(e) => {
                    setRisk(e.value);
                  }}
                  defaultValue={{ value: "residual", label: "Residual" }}
                />
              </Row>
              <Row>
                <Col md={6} sm={12}>
                  {risk === "residual" ? (
                    <StackedLineResidualChart
                      historicoresidual={historicoresidual}
                      getColor={getColor}
                    />
                  ) : (
                    <StackedLineInherenteChart
                      historicoinherente={historicoinherente}
                      getColor={getColor}
                    />
                  )}
                </Col>
                <Col md={6} sm={12}>
                  <StackedLineEfectividadChart
                    historicoefectividad={historicoefectividad}
                    getColor={getColor}
                    tipo={"ero"}
                  />
                </Col>
              </Row>
            </>
          ) : (
            <Alert variant="info">No hay datos históricos para mostrar</Alert>
          )}
          {openEditModal && (
            <ActivateDesactivateModal
              open={openEditModal}
              onClose={() => setOpenEditModal(false)}
              modalClasses={modalClasses}
              changeEvaluationStatus={changeEvaluationStatus}
              setEvaluacion={setEvaluacion}
              evaluacion={evaluacion}
            />
          )} */}
        </div>
      )}
    </div>
  );
};

export default EditarEvaluacion;
