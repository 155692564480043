import React, { useState, useEffect } from 'react';
import AppBar from "@mui/material/AppBar";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import EditarComponente from './EditarComponente';
import ClasificacionComponente from './ClasificacionComponente';

const MainEditarComponente = (props) => {
    const idCompania = localStorage.getItem("idcompania");
    const [valueWindow, setValueWindow] = useState(0);
    const ismodule = props.location.state ? props.location.state.value : 1;


    useEffect(() => {
        // Set initial tab based on idCompania
        setValueWindow(0);
    }, [idCompania]);

    const handleChange = (event, newValue) => {
        if (idCompania === "1" || newValue === 0) {
            setValueWindow(newValue);
        }
    };

    // const props = {
    //     "pathname": "/EditarComponente",
    //     "state": {
    //         "value": 1
    //     },
    //     "search": "",
    //     "hash": "",
    //     "key": "xj0vk3"
    // }

    return (
        <div>
            {console.log("IDCOMPANIA:", idCompania)}
            <AppBar position="static" style={{ backgroundColor: "#2c2a29", color: "#ffffff" }}>
                <Tabs value={valueWindow} onChange={handleChange}>
                    <Tab label="Detalle" style={{ color: "#ffffff", padding: "3px 7px 3px" }} />
                    {idCompania === "1" && ismodule == 1 ? (
                        <Tab label="Clasificación" style={{ color: "#ffffff", padding: "3px 7px 3px" }} disabled/>
                    ) : (
                        <Tab label="Clasificación" style={{ color: "#ffffff", padding: "3px 7px 3px" }} disabled />
                    )}
                </Tabs>
            </AppBar>
            {valueWindow === 0 && <EditarComponente ismodule={ismodule} />}
            {valueWindow === 1 && idCompania === "1" && ismodule == 1 && <ClasificacionComponente />}
        </div>
    );
};

export default MainEditarComponente;